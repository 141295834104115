import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./applicant.css";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import heading from "../Photos/heading.png";

function ApplicantRegistration() {
  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }

  // all thing for otp
  const [checkOtp, setCheckOtp] = useState(1213);
  const [phoneNo, setPhoneNo] = useState([]);
  const [recivedOtp, setRecivedOtp] = useState(12413);

  const info = () => {
    Axios.post("https://aajkaa-gsm.aajkaa.in/verifywhatsappnumber", {
      phoneNo: phoneNo,
    }).then((response) => {
      if (response.data === false) {
        setShow(true);
        setModalMessage("This WhatsApp number is already registered, Login using your candidate-ID");
      } else if (response.data == 'uploadimg') {
        setShow(true);
        setModalMessage("Photos upload pending");
      } else if (response.data == 'photos uploaded but status pending') {
        setShow(true);
        setModalMessage("Approval pending");
      } else {
        setShow(true);
        setModalMessage("OTP sent to your whatsapp number");
        setRecivedOtp(response.data);
      }
    });
  };


  const check = () => {
    if (checkOtp == recivedOtp) {
      Axios.post("https://aajkaa-gsm.aajkaa.in/retrivedatafromcandidatetable", {
        phoneNo: phoneNo,
      }).then((response) => {
        setCandidateID(response.data.map((val, key) => val.candidate_id));
        setCandidateFirstName(response.data.map((val, key) => val.candidate_firstname));
        setCandidateMiddleName(response.data.map((val, key) => val.candidate_middlename));
        setCandidatelastName(response.data.map((val, key) => val.candidate_lastname));
        setCandidateCurrentAddress(response.data.map((val, key) => val.candidate_current_address));
        
        let dobFromResponse = response.data.map((val) => val.dob)
        const originalDate = new Date(dobFromResponse);
        const nextDay = new Date(originalDate);
        nextDay.setDate(originalDate.getDate() + 1);
        const formattedNextDay = nextDay.toISOString().split('T')[0];
        setDateOfBirth(formattedNextDay)

        setMaritalStatus(response.data.map((val, key) => val.marital_status));
        setReligion(response.data.map((val, key) => val.religion));
        setGender(response.data.map((val, key) => val.gender));
        setHeight(response.data.map((val, key) => val.height));
        setWeight(response.data.map((val, key) => val.weight));
        setAnyDisability(response.data.map((val, key) => val.any_disability));
        setMotherTonge(response.data.map((val, key) => val.mother_tongue));
        setLanguagesKnown(response.data.map((val, key) => val.languages_known));
        setNationality(response.data.map((val, key) => val.nationality));
        setWhatsappNumber(response.data.map((val, key) => val.whatsapp_number));
        setEmail(response.data.map((val, key) => val.email_id));
        setHighestEducation(response.data.map((val, key) => val.qualification));
        setDegree(response.data.map((val, key) => val.degree));
        setCandidateOccupation(response.data.map((val, key) => val.candidate_occupation));
        setNatureOfWork(response.data.map((val, key) => val.nature_of_work));
        setcurrency(response.data.map((val, key) => val.currency));
        setCandidateAnnualIncome(response.data.map((val, key) => val.candidate_annual_income));
        setFatherAddress(response.data.map((val, key) => val.family_permanent_address));
        setFamilyAnnualIncome(response.data.map((val, key) => val.family_income));
        setGurudwaraVisited(response.data.map((val, key) => val.g_normally_visited));
        setGcontactPerson(response.data.map((val, key) => val.g_contact_person));
        setGcontactNumber(response.data.map((val, key) => val.g_contact_number));
        setRemarks(response.data.map((val, key) => val.additional_information));
        setReferenceName(response.data.map((val, key) => val.reference_name));
        setReferenceNumber(response.data.map((val, key) => val.reference_contact));
        document.getElementById("formform").hidden = false;
        document.getElementById("otpotp").hidden = true;
        Axios.post("https://aajkaa-gsm.aajkaa.in/familydata1", {
          CandidateID: response.data.map((val, key) => val.candidate_id),
        }).then((response) => {
          setFatherRowId(response.data.map((val, key) => val.id)[0]);
          setFatherName(response.data.map((val, key) => val.Relative_name)[0]);
          setFatherAge(response.data.map((val, key) => val.Relative_age)[0]);
          setFatherEducation(response.data.map((val, key) => val.Relative_Education)[0]);
          setFatherDegree(response.data.map((val, key) => val.Relative_Degree)[0]);
          setMotherRowId(response.data.map((val, key) => val.id)[1]);
          setMotherName(response.data.map((val, key) => val.Relative_name)[1]);
          setMotherAge(response.data.map((val, key) => val.Relative_age)[1]);
          setMotherEducation(response.data.map((val, key) => val.Relative_Education)[1]);
          setMotherDegree(response.data.map((val, key) => val.Relative_Degree)[1]);
          setRelative3RowId(response.data.map((val, key) => val.id)[2]);
          setRelative3(response.data.map((val, key) => val.Relationship)[2]);
          setRelative3Name(response.data.map((val, key) => val.Relative_name)[2]);
          setRelative3Age(response.data.map((val, key) => val.Relative_age)[2]);
          setRelative3Education(response.data.map((val, key) => val.Relative_Education)[2]);
          setRelative3Degree(response.data.map((val, key) => val.Relative_Degree)[2]);
          setRelative4RowId(response.data.map((val, key) => val.id)[3]);
          setRelative4(response.data.map((val, key) => val.Relationship)[3]);
          setRelative4Name(response.data.map((val, key) => val.Relative_name)[3]);
          setRelative4Age(response.data.map((val, key) => val.Relative_age)[3]);
          setRelative4Education(response.data.map((val, key) => val.Relative_Education)[3]);
          setRelative4Degree(response.data.map((val, key) => val.Relative_Degree)[3]);
          setRelative5RowId(response.data.map((val, key) => val.id)[4]);
          setRelative5(response.data.map((val, key) => val.Relationship)[4]);
          setRelative5Name(response.data.map((val, key) => val.Relative_name)[4]);
          setRelative5Age(response.data.map((val, key) => val.Relative_age)[4]);
          setRelative5Education(response.data.map((val, key) => val.Relative_Education)[4]);
          setRelative5Degree(response.data.map((val, key) => val.Relative_Degree)[4]);
          setRelative6RowId(response.data.map((val, key) => val.id)[5]);
          setRelative6(response.data.map((val, key) => val.Relationship)[5]);
          setRelative6Name(response.data.map((val, key) => val.Relative_name)[5]);
          setRelative6Age(response.data.map((val, key) => val.Relative_age)[5]);
          setRelative6Education(response.data.map((val, key) => val.Relative_Education)[5]);
          setRelative6Degree(response.data.map((val, key) => val.Relative_Degree)[5]);
          setRelative7RowId(response.data.map((val, key) => val.id)[6]);
          setRelative7(response.data.map((val, key) => val.Relationship)[6]);
          setRelative7Name(response.data.map((val, key) => val.Relative_name)[6]);
          setRelative7Age(response.data.map((val, key) => val.Relative_age)[6]);
          setRelative7Education(response.data.map((val, key) => val.Relative_Education)[6]);
          setRelative7Degree(response.data.map((val, key) => val.Relative_Degree)[6]);
        });
      });
    } else {
      setShow(true);
      setModalMessage("OTP incorrect. Try again...");
    }
  };

  const emailInputRef = useRef(null);

  const setCursorPosition = () => {
    if (emailInputRef.current) {
      emailInputRef.current.type = "text";
      emailInputRef.current.setSelectionRange(0, 0);
      emailInputRef.current.type = "email";
    }
  };
  // all thing for form
  const [CandidateID, setCandidateID] = useState("");
  const [candidateFirstName, setCandidateFirstName] = useState("");
  const [candidateMiddleName, setCandidateMiddleName] = useState("");
  const [candidatelastName, setCandidatelastName] = useState("");
  const [CandidateCurrentAddress, setCandidateCurrentAddress] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [Religion, setReligion] = useState("");
  const [Gender, setGender] = useState("");
  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [AnyDisability, setAnyDisability] = useState("");
  const [MotherTonge, setMotherTonge] = useState("");
  const [LanguagesKnown, setLanguagesKnown] = useState("");
  const [Nationality, setNationality] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [HighestEducation, setHighestEducation] = useState("");
  const [degree, setDegree] = useState("");
  const [CandidateOccupation, setCandidateOccupation] = useState("");
  const [NatureOfWork, setNatureOfWork] = useState("");
  const [Currency, setcurrency] = useState("");
  const [CandidateAnnualIncome, setCandidateAnnualIncome] = useState("");
  const [FatherAddress, setFatherAddress] = useState("");
  const [FamilyAnnualIncome, setFamilyAnnualIncome] = useState("");
  const [GurudwaraVisited, setGurudwaraVisited] = useState("");
  const [GcontactPerson, setGcontactPerson] = useState("");
  const [GcontactNumber, setGcontactNumber] = useState("");
  const [fatherRowid, setFatherRowId] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherAge, setFatherAge] = useState("");
  const [fatherEducation, setFatherEducation] = useState("");
  const [fatherDegree, setFatherDegree] = useState("");
  const [motherRowid, setMotherRowId] = useState("");
  const [mothername, setMotherName] = useState("");
  const [motherage, setMotherAge] = useState("");
  const [motherEducation, setMotherEducation] = useState("");
  const [motherDegree, setMotherDegree] = useState("");
  const [relative3RowId, setRelative3RowId] = useState("");
  const [relative3, setRelative3] = useState("");
  const [relative3name, setRelative3Name] = useState("");
  const [relative3age, setRelative3Age] = useState("");
  const [relative3Education, setRelative3Education] = useState("");
  const [relative3Degree, setRelative3Degree] = useState("");
  const [relative4RowId, setRelative4RowId] = useState("");
  const [relative4, setRelative4] = useState("");
  const [relative4name, setRelative4Name] = useState("");
  const [relative4age, setRelative4Age] = useState("");
  const [relative4Education, setRelative4Education] = useState("");
  const [relative4Degree, setRelative4Degree] = useState("");
  const [relative5RowId, setRelative5RowId] = useState("");
  const [relative5, setRelative5] = useState("");
  const [relative5name, setRelative5Name] = useState("");
  const [relative5age, setRelative5Age] = useState("");
  const [relative5Education, setRelative5Education] = useState("");
  const [relative5Degree, setRelative5Degree] = useState("");
  const [relative6RowId, setRelative6RowId] = useState("");
  const [relative6, setRelative6] = useState("");
  const [relative6name, setRelative6Name] = useState("");
  const [relative6age, setRelative6Age] = useState("");
  const [relative6Education, setRelative6Education] = useState("");
  const [relative6Degree, setRelative6Degree] = useState("");
  const [relative7RowId, setRelative7RowId] = useState("");
  const [relative7, setRelative7] = useState("");
  const [relative7name, setRelative7Name] = useState("");
  const [relative7age, setRelative7Age] = useState("");
  const [relative7Education, setRelative7Education] = useState("");
  const [relative7Degree, setRelative7Degree] = useState("");
  const [remarks, setRemarks] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [projectHeading, setProjectHeading] = useState([]);
  const [dbMaritalStatusOption, setDbMaritalStatusOption] = useState([]);
  const [dbReligionOption, setDbReligionOption] = useState([]);
  const [dbGenderOption, setDbGenderOption] = useState([]);
  const [dbEducationOption, setDbEducationOption] = useState([]);
  const [dbOccupationOption, setDbOccupationOption] = useState([]);
  const [dbCurrencyOption, setDbCurrencyOption] = useState([]);
  const [dbNature_of_workOption, setDbNature_of_workOption] = useState([]);
  const [dbRelationshipOption, setDbRelationshipOption] = useState([]);


  const [requiredInReference, setRequiredInReference] = useState(false);

  useEffect(() => {
    Axios.post("https://aajkaa-gsm.aajkaa.in/getprojectheading").then((response) => {
      setProjectHeading(response.data);
      if (response.data[0].approve_by == 'ExistingReference' || response.data[0].approve_by == 'AnyReference') {
        setRequiredInReference(true);
      }
    });
    Axios.post("https://aajkaa-gsm.aajkaa.in/ddls").then((response) => {
      setDbMaritalStatusOption(response.data[0].marital_status.split(','))
      setDbReligionOption(response.data[0].religion.split(','))
      setDbGenderOption(response.data[0].gender.split(','))
      setDbEducationOption(response.data[0].education.split(','))
      setDbOccupationOption(response.data[0].occupation.split(','))
      setDbCurrencyOption(response.data[0].currency.split(','))
      setDbNature_of_workOption(response.data[0].nature_of_work.split(','))
      setDbRelationshipOption(response.data[0].relationship.split(','))
    });
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    // Create Bootstrap tooltips for each trigger element
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );

    // Clean up tooltips when the component unmounts
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  // console.log(projectHeading[0].projectheading)
  const handleBlur = async (inputName, value) => {
    try {
      console.log([inputName], value);
      await Axios.post('https://aajkaa-gsm.aajkaa.in/updatecandidate', {
        [inputName]: value,
        candidateid: CandidateID,
      });
      console.log(`${inputName} saved to the server`);
    } catch (error) {
      console.error(`Error saving ${inputName} to the server`, error);
    }
  };

  const familyhandleBlur = async (rowid, inputName, value) => {
    try {
      console.log([inputName], value);
      await Axios.post('https://aajkaa-gsm.aajkaa.in/updatefamilydata', {
        [inputName]: value,
        candidateid: CandidateID,
        rowid: rowid,
      });
      console.log(`${inputName} ${value} ${rowid} ${CandidateID} saved to the server`);
    } catch (error) {
      console.error(`Error saving ${inputName} to the server`, error);
    }
  };

  const saveapplicant = async (e) => {
    e.preventDefault();
    // if (referenceNumber != '' && (projectHeading[0].approve_by === 'Approver Or Existing Reference' || projectHeading[0].approve_by === 'ExistingReference')) {
    //   Axios.post('https://aajkaa-gsm.aajkaa.in/checkreferencewhatsappno', {
    //     referenceNumber: referenceNumber,
    //   }).then((response) => {
    //     if (!response.data) {
    //       setShow(true);
    //       setModalMessage(
    //         "Reference person is not an existing candidate"
    //       )
    //     }
    // else {
    Axios.post("https://aajkaa-gsm.aajkaa.in/upload", {
      CandidateID: CandidateID,
      CandidateFullName: candidateFirstName,
      candidateSurname: candidatelastName,
      candidatelastName: candidateMiddleName,
      CandidateCurrentAddress: CandidateCurrentAddress,
      DateOfBirth: DateOfBirth,
      MaritalStatus: MaritalStatus,
      Religion: Religion,
      Gender: Gender,
      Height: Height,
      Weight: Weight,
      AnyDisability: AnyDisability,
      MotherTonge: MotherTonge,
      LanguagesKnown: LanguagesKnown,
      Nationality: Nationality,
      WhatsappNumber: WhatsappNumber,
      Email: Email,
      degree: degree,
      HighestEducation: HighestEducation,
      CandidateOccupation: CandidateOccupation,
      NatureOfWork: NatureOfWork,
      Currency: Currency,
      CandidateAnnualIncome: CandidateAnnualIncome,
      FatherAddress: FatherAddress,
      FamilyAnnualIncome: FamilyAnnualIncome,
      GurudwaraVisited: GurudwaraVisited,
      GcontactPerson: GcontactPerson,
      GcontactNumber: GcontactNumber,
      fatherName: fatherName,
      fatherAge: fatherAge,
      fatherEducation: fatherEducation,
      fatherDegree: fatherDegree,
      mothername: mothername,
      motherage: motherage,
      motherEducation: motherEducation,
      motherDegree: motherDegree,
      relative3: relative3,
      relative3name: relative3name,
      relative3age: relative3age,
      relative3Education: relative3Education,
      relative3Degree: relative3Degree,
      relative4: relative4,
      relative4name: relative4name,
      relative4age: relative4age,
      relative4Education: relative4Education,
      relative4Degree: relative4Degree,
      relative5: relative5,
      relative5name: relative5name,
      relative5age: relative5age,
      relative5Education: relative5Education,
      relative5Degree: relative5Degree,
      relative6: relative6,
      relative6name: relative6name,
      relative6age: relative6age,
      relative6Education: relative6Education,
      relative6Degree: relative6Degree,
      relative7: relative7,
      relative7name: relative7name,
      relative7age: relative7age,
      relative7Education: relative7Education,
      relative7Degree: relative7Degree,
      remarks: remarks,
      referenceName: referenceName,
      referenceNumber: referenceNumber,
    }).then(
      Axios.post("https://aajkaa-gsm.aajkaa.in/sendcandidateidonw", {
        CandidateNumber: phoneNo,
        CandidateID: CandidateID,
      }).then(() => {
        setShow(true);
        setModalMessage(
          "Form submitted successfully. Applicant Id sent to your Whatsapp number. Login-password will be sent when the profile is approved."
        );
      }
      ).catch((error) => console.log("error", error))
    );
    //     }
    //   }
    //   )
    // }
  };

  // managing height in feet and inch
  const [cmValue, setCmValue] = useState(0);
  const [feet, setFeetValue] = useState(0);
  const cmToFeet = (cm) => {
    const totalInches = Math.round(cm / 2.54);
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;
    return `${feet} feet ${inches} inches`;
  };

  // handle modal
  const [modalMessage, setModalMessage] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <div className="container">
      {/* modal bootstrap */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">{modalMessage}</Modal.Body>
        <Modal.Footer>
          {modalMessage ==
            "This WhatsApp number is already registered, Login using your candidate-ID" ? (
            <>
              <Link
                className="btn btn-success"
                to={projectHeading[0].loginurl}
                target='_blank'
              >
                Login
              </Link>
              <Link
                className="btn btn-danger"
                to="/resendapplicantid"
              >
                Forgot Candidate ID
              </Link>
            </>
          ) : <></>}
          {modalMessage ==
            "Photos upload pending" ? (
            <>
              <Link
                className="btn btn-success"
                to="/EditApplicationForm"
              >
                Modify Application
              </Link>
            </>
          ) : <></>}
          {modalMessage ==
            "Approval pending" ? (
            <>
              <Link
                className="btn btn-success"
                to="/EditApplicationForm"
              >
                Modify Application
              </Link>
            </>
          ) : <></>}
          {modalMessage ==
            "Form submitted successfully. Applicant Id sent to your Whatsapp number. Login-password will be sent when the profile is approved." ? (
            <Link
              className="btn btn-primary"
              to={{
                pathname: "/uploadformphoto",
                state: {
                  CandidateID,
                  phoneNo,
                  candidateFirstName,
                  candidatelastName,
                  candidateMiddleName,
                  referenceName,
                  referenceNumber,
                  Gender,
                  isEdit: false,
                  countofsendingreference: 0,
                },
              }}
            >
              OK
            </Link>
          ) : (
            <Button variant="secondary" onClick={handleClose} autoFocus>
              Ok
            </Button>
          )}

        </Modal.Footer>
      </Modal>
      {/* project heading */}
      <h1 className="text-primary text-center mt-2">
        {projectHeading.length > 0 ? projectHeading[0].projectheading :
          <>
            <div className="spinner-border fs-6" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        }
      </h1>
      <h2 className="text-primary text-center mt-2">
        {checkOtp == recivedOtp ?
          <>Fill Application Form</>
          :
          <>Whatsapp Number Verification</>
        }
      </h2>

      <div className="mx-3 my-1">
        {/* verify otp code */}
        <div className="row g-3" id="otpotp">
          <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 d-flex flex-wrap justify-content-between align-items-center">
            <span>
              Please provide <u>your Whatsapp phone number</u> to be used for
              future communication and follow-up
            </span>
            <Link
              className="mt-2 btn btn-primary order-last order-md-last"
              onClick={gotodashboard}
            >
              <i className="bi bi-caret-left-fill mx-2"></i> Back
            </Link>
          </div>

          <div className="col-md-3">
            <input className="form-control" id="input" type="number" placeholder="Enter WhatsApp number" onChange={(event) => { setPhoneNo(event.target.value); setWhatsappNumber(event.target.value); }} autoComplete="off" autoFocus />
          </div>
          <div className="col-md-9">
            <Link to="/ApplicantRegistration" className="btn btn-primary" onClick={info} >
              Get OTP
            </Link>
          </div>
          <div className="col-md-3">
            <input className="form-control" type="number" placeholder="Enter OTP" onChange={(event) => { setCheckOtp(event.target.value); }} />
          </div>
          <div className="col-md-3 mb-4">
            <Link to="/ApplicantRegistration" className="btn btn-primary" onClick={check} >
              Verify OTP
            </Link>
          </div>
        </div>
        {/* verify otp code complete */}

        {/* started form */}
        <form
          onLoad="genCandidateID"
          className="row g-3"
          onSubmit={saveapplicant}
          id="formform"
          hidden
        >
          <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3">
            Application Form
          </div>

          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's First Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={candidateFirstName}
              onChange={(e) => setCandidateFirstName(e.target.value)}
              onBlur={() => handleBlur('candidate_firstname', candidateFirstName)}
              required
              autoFocus
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's Middle Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={candidateMiddleName}
              onChange={(e) => setCandidateMiddleName(e.target.value)}
              onBlur={() => handleBlur('candidate_middlename', candidateMiddleName)}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's Last Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={candidatelastName}
              onChange={(e) => setCandidatelastName(e.target.value)}
              onBlur={() => handleBlur('candidate_lastname', candidatelastName)}
              required
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Applicant's Current Address <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={CandidateCurrentAddress}
              onChange={(e) => setCandidateCurrentAddress(e.target.value)}
              onBlur={() => handleBlur('candidate_current_address', CandidateCurrentAddress)}
              required
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Date of Birth <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="date"
              value={DateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              onBlur={() => handleBlur('dob', DateOfBirth)}
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Marital Status <span className="text-danger">*</span>
            </label>
            <select className="form-select" onChange={(e) => setMaritalStatus(e.target.value)} onBlur={() => handleBlur('marital_status', MaritalStatus)}>
              <option disabled selected={MaritalStatus == '' ? true : false}>
                Select Marital Status
              </option>
              {dbMaritalStatusOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={MaritalStatus == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Religion <span className="text-danger">*</span>
            </label>
            <select className="form-select" onChange={(e) => setReligion(e.target.value)} onBlur={() => handleBlur('religion', Religion)}>
              <option disabled selected={Religion == '' ? true : false}>
                Select Religion
              </option>
              {dbReligionOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={Religion == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Gender <span className="text-danger">*</span>
            </label>
            <select className="form-select" onChange={(e) => setGender(e.target.value)} onBlur={() => handleBlur('gender', Gender)}>
              <option disabled selected={Gender == '' ? true : false}>
                Select Gender
              </option>
              {dbGenderOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={Gender == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>

          <div className="col-md-4">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Height : {feet} <span className="text-danger">*</span>
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                cm
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="inputGroupPrepend"
                value={Height}
                onBlur={() => handleBlur('height', Height)}
                onChange={(event) => {
                  setHeight(event.target.value);
                  const cm = event.target.value;
                  const feet = cmToFeet(cm);
                  setCmValue(cm);
                  setFeetValue(feet);
                }}
                required
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>

          <div className="col-md-4">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Weight <span className="text-danger">*</span>
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                kg
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="inputGroupPrepend"
                value={Weight}
                onBlur={() => handleBlur('weight', Weight)}
                onChange={(event) => {
                  setWeight(event.target.value);
                }}
                required
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Any Disability?
            </label>
            <input
              className="form-control"
              type="text"
              value={AnyDisability}
              onBlur={() => handleBlur('any_disability', AnyDisability)}
              onChange={(event) => {
                setAnyDisability(event.target.value);
              }}
              placeholder="mental and/or physical"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Mother Tongue <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={MotherTonge}
              onBlur={() => handleBlur('mother_tongue', MotherTonge)}
              onChange={(event) => {
                setMotherTonge(event.target.value);
              }}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Languages Known
            </label>
            <input
              className="form-control"
              type="text"
              value={LanguagesKnown}
              onBlur={() => handleBlur('languages_known', LanguagesKnown)}
              onChange={(event) => {
                setLanguagesKnown(event.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Nationality <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={Nationality}
              onBlur={() => handleBlur('nationality', Nationality)}
              onChange={(event) => {
                setNationality(event.target.value);
              }}
              required
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Verified Phone Number
            </label>
            <input
              className="form-control"
              type="number"
              disabled
              value={phoneNo}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Email-ID
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                @
              </span>
              <input
                id="emailInput"
                className="form-control"
                type="email"
                aria-describedby="inputGroupPrepend"
                value={Email}
                ref={emailInputRef}
                onFocus={setCursorPosition}
                onBlur={() => handleBlur('email_id', Email)}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Education <span className="text-danger">*</span>
            </label>
            <select className="form-select" onChange={(e) => setHighestEducation(e.target.value)} onBlur={() => handleBlur('qualification', HighestEducation)}>
              <option disabled selected={HighestEducation == '' ? true : false}>
                Select Education
              </option>
              {dbEducationOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={HighestEducation == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Highest Degree
            </label>
            <input
              className="form-control"
              type="text"
              value={degree}
              onBlur={() => handleBlur('degree', degree)}
              onChange={(event) => {
                setDegree(event.target.value);
              }}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Applicant's Occupation <span className="text-danger">*</span>
            </label>
            <select className="form-select" onChange={(e) => setCandidateOccupation(e.target.value)} onBlur={() => handleBlur('candidate_occupation', CandidateOccupation)}>
              <option disabled selected={CandidateOccupation == '' ? true : false}>
                Select Occupation
              </option>
              {dbOccupationOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={CandidateOccupation == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Nature Of Work
            </label>
            <select className="form-select" onChange={(e) => setNatureOfWork(e.target.value)} onBlur={() => handleBlur('nature_of_work', NatureOfWork)}>
              <option disabled selected={NatureOfWork == '' ? true : false}>
                Select Nature Of Work
              </option>
              {dbNature_of_workOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={NatureOfWork == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>
          <div className="col-md-2">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Currency <span className="text-danger">*</span>
            </label>
            <select className="form-select" onChange={(e) => setcurrency(e.target.value)} onBlur={() => handleBlur('currency', Currency)}>
              <option disabled selected={Currency == '' ? true : false}>
                Select Currency
              </option>
              {dbCurrencyOption.map((val, key) => {
                return (
                  <>
                    <option value={val} selected={Currency == val ? true : false}>{val}</option>
                  </>
                );
              })
              }
            </select>
          </div>
          <div className="col-md-3">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Applicant's Annual Income <span className="text-danger">*</span>
            </label>
            <div className="input-group has-validation">
              <input
                type="number"
                className="form-control"
                id="validationCustomUsername"
                aria-describedby="inputGroupPrepend"
                value={CandidateAnnualIncome}
                onBlur={() => handleBlur('candidate_annual_income', CandidateAnnualIncome)}
                onChange={(event) => {
                  setCandidateAnnualIncome(event.target.value);
                }}
                required
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>

          <div className="mt-3">
            <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">
              Family & Relatives
            </div>

            <div className="row g-3">
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  value="Father"
                  disabled
                />
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Father Name"
                  value={fatherName}
                  onBlur={() => familyhandleBlur(fatherRowid, 'Relative_name', fatherName)}
                  onChange={(event) => {
                    setFatherName(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Father Age"
                  value={fatherAge}
                  onBlur={() => familyhandleBlur(fatherRowid, 'Relative_age', fatherAge)}
                  onChange={(event) => {
                    setFatherAge(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setFatherEducation(e.target.value)} onBlur={() => familyhandleBlur(fatherRowid, 'Relative_Education', fatherEducation)}>
                  <option disabled selected={fatherEducation == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={fatherEducation == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setFatherDegree(e.target.value)} onBlur={() => familyhandleBlur(fatherRowid, 'Relative_Degree', fatherDegree)}>
                  <option disabled selected={fatherDegree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={fatherDegree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>


              <hr className="d-md-none" />

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  value="Mother"
                  disabled
                />
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Mother Name"
                  value={mothername}
                  onBlur={() => familyhandleBlur(motherRowid, 'Relative_name', mothername)}
                  onChange={(event) => {
                    setMotherName(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Mother Age"
                  value={motherage}
                  onBlur={() => familyhandleBlur(motherRowid, 'Relative_age', motherage)}
                  onChange={(event) => {
                    setMotherAge(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setMotherEducation(e.target.value)} onBlur={() => familyhandleBlur(motherRowid, 'Relative_Education', motherEducation)}>
                  <option disabled selected={motherEducation == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={motherEducation == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setMotherDegree(e.target.value)} onBlur={() => familyhandleBlur(motherRowid, 'Relative_Degree', motherDegree)}>
                  <option disabled selected={motherDegree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={motherDegree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative3(e.target.value)} onBlur={() => familyhandleBlur(relative3RowId, 'Relationship', relative3)}>
                  <option disabled selected={relative3 == '' ? true : false}>
                    Relationship
                  </option>
                  {dbRelationshipOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative3 == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  value={relative3name}
                  onBlur={() => familyhandleBlur(relative3RowId, 'Relative_name', relative3name)}
                  onChange={(event) => {
                    setRelative3Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  value={relative3age}
                  onBlur={() => familyhandleBlur(relative3RowId, 'Relative_age', relative3age)}
                  onChange={(event) => {
                    setRelative3Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative3Education(e.target.value)} onBlur={() => familyhandleBlur(relative3RowId, 'Relative_Education', relative3Education)}>
                  <option disabled selected={relative3Education == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative3Education == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setRelative3Degree(e.target.value)} onBlur={() => familyhandleBlur(relative3RowId, 'Relative_Degree', relative3Degree)}>
                  <option disabled selected={relative3Degree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative3Degree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative4(e.target.value)} onBlur={() => familyhandleBlur(relative4RowId, 'Relationship', relative4)}>
                  <option disabled selected={relative4 == '' ? true : false}>
                    Relationship
                  </option>
                  {dbRelationshipOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative4 == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  value={relative4name}
                  onBlur={() => familyhandleBlur(relative4RowId, 'Relative_name', relative4name)}
                  onChange={(event) => {
                    setRelative4Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  value={relative4age}
                  onBlur={() => familyhandleBlur(relative4RowId, 'Relative_age', relative4age)}
                  onChange={(event) => {
                    setRelative4Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative4Education(e.target.value)} onBlur={() => familyhandleBlur(relative4RowId, 'Relative_Education', relative4Education)}>
                  <option disabled selected={relative4Education == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative4Education == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setRelative4Degree(e.target.value)} onBlur={() => familyhandleBlur(relative4RowId, 'Relative_Degree', relative4Degree)}>
                  <option disabled selected={relative4Degree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative4Degree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative5(e.target.value)} onBlur={() => familyhandleBlur(relative5RowId, 'Relationship', relative5)}>
                  <option disabled selected={relative5 == '' ? true : false}>
                    Relationship
                  </option>
                  {dbRelationshipOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative5 == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  value={relative5name}
                  onBlur={() => familyhandleBlur(relative5RowId, 'Relative_name', relative5name)}
                  onChange={(event) => {
                    setRelative5Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  value={relative5age}
                  onBlur={() => familyhandleBlur(relative5RowId, 'Relative_age', relative5age)}
                  onChange={(event) => {
                    setRelative5Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative5Education(e.target.value)} onBlur={() => familyhandleBlur(relative5RowId, 'Relative_Education', relative5Education)}>
                  <option disabled selected={relative5Education == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative5Education == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setRelative5Degree(e.target.value)} onBlur={() => familyhandleBlur(relative5RowId, 'Relative_Degree', relative5Degree)}>
                  <option disabled selected={relative5Degree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative5Degree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative6(e.target.value)} onBlur={() => familyhandleBlur(relative6RowId, 'Relationship', relative6)}>
                  <option disabled selected={relative6 == '' ? true : false}>
                    Relationship
                  </option>
                  {dbRelationshipOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative6 == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  value={relative6name}
                  onBlur={() => familyhandleBlur(relative6RowId, 'Relative_name', relative6name)}
                  onChange={(event) => {
                    setRelative6Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  value={relative6age}
                  onBlur={() => familyhandleBlur(relative6RowId, 'Relative_age', relative6age)}
                  onChange={(event) => {
                    setRelative6Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative6Education(e.target.value)} onBlur={() => familyhandleBlur(relative6RowId, 'Relative_Education', relative6Education)}>
                  <option disabled selected={relative6Education == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative6Education == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setRelative6Degree(e.target.value)} onBlur={() => familyhandleBlur(relative6RowId, 'Relative_Degree', relative6Degree)}>
                  <option disabled selected={relative6Degree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative6Degree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <hr className="d-md-none" />


              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative7(e.target.value)} onBlur={() => familyhandleBlur(relative7RowId, 'Relationship', relative7)}>
                  <option disabled selected={relative7 == '' ? true : false}>
                    Relationship
                  </option>
                  {dbRelationshipOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative7 == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  value={relative7name}
                  onBlur={() => familyhandleBlur(relative7RowId, 'Relative_name', relative7name)}
                  onChange={(event) => {
                    setRelative7Name(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  value={relative7age}
                  onBlur={() => familyhandleBlur(relative7RowId, 'Relative_age', relative7age)}
                  onChange={(event) => {
                    setRelative7Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select className="form-select" onChange={(e) => setRelative7Education(e.target.value)} onBlur={() => familyhandleBlur(relative7RowId, 'Relative_Education', relative7Education)}>
                  <option disabled selected={relative7Education == '' ? true : false}>
                    Education
                  </option>
                  {dbEducationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative7Education == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={(e) => setRelative7Degree(e.target.value)} onBlur={() => familyhandleBlur(relative7RowId, 'Relative_Degree', relative7Degree)}>
                  <option disabled selected={relative7Degree == '' ? true : false}>
                    Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => {
                    return (
                      <>
                        <option value={val} selected={relative7Degree == val ? true : false}>{val}</option>
                      </>
                    );
                  })
                  }
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-9">
                <label htmlFor="validationCustom02" className="form-label fs-5">
                  Father's / Family's Permanent Address
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={FatherAddress}
                  onBlur={() => handleBlur('family_permanent_address', FatherAddress)}
                  onChange={(event) => {
                    setFatherAddress(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Family's Annual Income
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    ₹
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustomUsername"
                    aria-describedby="inputGroupPrepend"
                    value={FamilyAnnualIncome}
                    onBlur={() => handleBlur('family_income', FamilyAnnualIncome)}
                    onChange={(event) => {
                      setFamilyAnnualIncome(event.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    Please choose a username.
                  </div>
                </div>
              </div>

              <div className="h4 pb-2 mb-1 text-primary border-bottom border-primary">
                Gurudwara Details
              </div>

              <div className="col-md-6">
                <label htmlFor="validationCustom02" className="form-label">
                  Gurudwara Normally visited : Name & Address{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={GurudwaraVisited}
                  onBlur={() => handleBlur('g_normally_visited', GurudwaraVisited)}
                  onChange={(event) => {
                    setGurudwaraVisited(event.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Name of Contact Person at Gurudwara
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={GcontactPerson}
                  onBlur={() => handleBlur('g_contact_person', GcontactPerson)}
                  onChange={(event) => {
                    setGcontactPerson(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Phone no. of contact person
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={GcontactNumber}
                  onBlur={() => handleBlur('g_contact_number', GcontactNumber)}
                  onChange={(event) => {
                    setGcontactNumber(event.target.value);
                  }}
                />
              </div>

              <div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">
                Any Other Information
              </div>

              <div className="col-md-12">
                <textarea
                  className="form-control"
                  value={remarks}
                  onBlur={() => handleBlur('additional_information', remarks)}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                ></textarea>
              </div>

              {projectHeading.length > 0 ? (
                projectHeading[0].approve_by === 'Approver Or Existing Reference' || projectHeading[0].approve_by === 'ExistingReference' || projectHeading[0].approve_by === 'AnyReference' ? (

                  <>
                    <div className="container text-primary border-bottom border-primary mt-3">
                      <div className="row">
                        <div className="col-5 h4 d-flex align-items-center">
                          <p className="mb-0">Reference details:</p>
                        </div>
                        <div className="col text-right d-flex align-items-center">
                          <i className="bi bi-exclamation-triangle-fill text-warning mx-3"></i>
                          <p className="mb-0">
                            Kindly note: Your profile will be published only after the
                            reference person confirms.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="validationCustom02" className="form-label">
                        Reference Person Name
                        {requiredInReference ?
                          <span className="text-danger">*</span>
                          :
                          <></>
                        }
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={referenceName}
                        onBlur={() => handleBlur('reference_name', referenceName)}
                        onChange={(event) => {
                          setReferenceName(event.target.value);
                        }}
                        required={requiredInReference}
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="validationCustom02" className="form-label">
                        Reference WhatsApp Number{" "}
                        {requiredInReference ?
                          <span className="text-danger">*</span>
                          :
                          <></>
                        }
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        value={referenceNumber}
                        onBlur={() => handleBlur('reference_contact', referenceNumber)}
                        onChange={(event) => {
                          setReferenceNumber(event.target.value);
                        }}
                        required={requiredInReference}
                      />
                    </div>
                  </>
                ) : (
                  // JSX content when condition is false
                  null
                )
              ) : (
                // JSX content when projectHeading.length <= 0
                <div className="spinner-border fs-6" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}


              <div className="col-md-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    required
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    I have read and agree to the
                  </label>
                  <Link
                    to="ApplicantRegistration"
                    type="button"
                    className="mx-2"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ textDecoration: "" }}
                  >
                    Terms And Conditions
                  </Link>
                </div>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">
                          Global Sikh Matrimony
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body text-primary">
                        <ul>
                          <li>
                            It is important for the Candidates, Applicants and their family members to note that this matrimonial service is a free service to the applicants / candidates. The purpose of this service is to provide a common platform for the members of our community.
                          </li>
                          <br />
                          <li>
                            It is the responsibility of the applicant / candidate to verify the authenticity of the information provided by other applicants / candidates. The Sikh Foundation, members of the Sikh Foundation, the developers of this portal take no responsibility regarding the authenticity of the information provided by any applicant / candidate.
                          </li>
                        </ul>

                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            {/* save button */}
            <input
              type="submit"
              className="btn btn-primary"
              value="Save and Proceed"
            />
            {/* modal bootstarp */}
            <div
              className="modal fade"
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Applicant-ID assigned
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Your Applicant-ID is: {CandidateID}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* modal bootstarp end */}
            <Link
              onClick={gotodashboard}
              className="btn btn-primary mx-2"
              role="button"
            >
              Back
            </Link>
          </div>
          <button onClick={gotodashboard} className="btn btn-primary" data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="custom-tooltip"
            data-bs-title="Continue Later" style={{ position: 'fixed', bottom: '20px', right: '20px', width: "50px" }} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy-fill" viewBox="0 0 16 16">
              <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
              <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
}

export default ApplicantRegistration;
